@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.socials_footer{
    font-size: 1.6em;
    display: flex;
}
.particle {
    position: fixed;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 9999999 !important;
}

body {
    font-family: 'Montserrat', sans-serif !important;
    margin: 0 !important;
}

input, div, * {
    font-family: 'Montserrat', sans-serif !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.ant-layout{
    min-height: 100vh;
}
.ant-layout-header {
    height: 64px;
    line-height: 64px;
    box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 11px 50px;
}

.ant-layout-header img {
    height: 100%;
}

.ant-layout-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-layout-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 10vh;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.steps_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
    border-radius: 6px;
    border: 1px dashed #e8e8e8;
    margin-top: 16px;
    min-height: 260px;
    background-color: #ececec;
    padding: 36px;
    width: 100%;
}
.steps_button{
    margin-left: 6px;
}
.facebookPixelIdInput{
    width: 200px;
}
.ant-float-btn-icon{
    display: flex;
}
.steps_subtitle{
    text-align: left;
    font-size: 1.2em;
    font-weight: 600;
    color: #111111;
}

.facebookCodeBlock > pre {
    width: 600px !important;
    max-width: 100% !important;
}

.fa-instagram {
    color: orangered;
}

.fa-telegram {
    color: cornflowerblue;
}

.fa-facebook {
    color: blue;
}

.fa-youtube {
    color: red;
}

.fa-viber {
    color: blueviolet;
}